import React from 'react'

import Layout from '../components/layout'
import { Section, Container } from '../components/containers/Layout'
import { RequestDemoForm } from '../components/RequestDemoForm'
import Helmet from 'react-helmet'

const shadow = (v = 1) => ({
  boxShadow: `0 ${3 + v / 4}px ${10 * v}px rgba(0, 0, 0, ${0.01 * v})`,
})

class RequestDemoPage extends React.Component {
  render() {
    return (<Layout>
      <Helmet
          title={'Bite AI - Contact Us'}
          meta={[
            {
              name: 'description',
              content: 'Bite AI - Food Intelligence Platform',
            },
            {
              property: 'og:description',
              content: 'Bite AI - Food Intelligence Platform',
            },
            {
              name: 'keywords',
              content:
                  'food recognition, api, deep learning, image recognition,' +
                  'food recognition api, food search, food image recognition,' +
                  ' food knowledge graph',
            },
          ]}
      />
      <Section>
        <Container style={{justifyContent: 'center'}}>
          <div
              style={{
                border: '1px solid #EEE',
                borderRadius: 8,
                padding: `50px 40px`,
                backgroundColor: 'white',
                ...shadow(5),
              }}>
            <RequestDemoForm product={this.props.location.state ? this.props.location.state.product : undefined}/>
          </div>
        </Container>
      </Section>
    </Layout>)
  }
}


export default RequestDemoPage
